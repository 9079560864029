import React, { Suspense, useEffect, useState, useRef } from "react"
import { Link, Outlet,useLocation } from "react-router-dom"
import api from './api'
import { lang } from '../lang'
import th_icon from '../assets/th.jpg';
import en_icon from '../assets/en.jpg';
import ch_icon from '../assets/ch.png';
import logo from '../assets/main-logo.png';
import Footer from './Footer'
import ScrollToTop from './ScrollToTop';
import { FaPhoneAlt, FaFacebookF, FaEnvelope, FaTwitter, FaAlignRight, FaChevronDown, FaExclamationTriangle } from 'react-icons/fa';


const Layout = () => {
    const [menus, setMenus] = useState([]);
    const [init, setInit] = useState([]);
    const [language, setLang] = useState("th");
    const [mobile_menu, setMobileMenu] = useState(false);
    const [warning, setWarning] = useState(localStorage.getItem("warning"));
    const [chk_menu, setChkMenu] = useState(1);
    const [header_color, setHeaderColor] = useState("#fff");
    const [bg_color, setBGColor] = useState("#fff");
    const location = useLocation();
    const [scrolltop_show, setScrolltopShow] = useState(false);
    const ref = useRef(null);
  
    useEffect(()=>{
        setLang(prev => localStorage.getItem("lang"));
        const fetchMenus = async () =>{
          try {
            const response = await api.get('/menu?type=main-menu&order=order');
            let menu = [];
            response.data.results.forEach(item => {
                let menu_active = false;
                if(location.pathname===item.link){
                  menu_active = true;
                  if(menu[item.parent_menu_id]){
                    menu[item.parent_menu_id].active = true;
                  }
                }
                if(item.parent_menu_id==="0"){
                  menu[item.id] = {
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    menu_cn: item.menu_cn,
                    link: item.link,
                    menu_type: item.menu_type,
                    order: item.order,
                    active: menu_active,
                    show: menu_active,
                    child: []
                  }
                }else{
                  let tmp = {
                    menu_id: item.id,
                    menu_th: item.menu_th,
                    menu_en: item.menu_en,
                    menu_cn: item.menu_cn,
                    link: item.link,
                    menu_type: item.menu_type,
                    order: item.order,
                    active: menu_active,
                    show: menu_active,
                    child: []
                  };
                  menu[item.parent_menu_id].child.push(tmp);
                }
            });
            // console.log(menu);
            setMenus(menu);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
  
          try {
            const response = await api.get('/setting');
            setInit(response.data.results);
            const home_config = JSON.parse(response.data.results.config);
            setHeaderColor(home_config.header_color);
            setBGColor(home_config.bg_color);
            // console.log(home_config.header_color);
            // console.log(home_config.bg_color);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchMenus();
    },[]);
  
    const onScroll = (event) => {
      if(event.target.scrollTop> 130){
          setScrolltopShow(true);
      }else{
          setScrolltopShow(false);
      }
  };

  const scrollToTop = (el) => {
      ref.current.scrollTo({
        top: 1,
        left: 0,
        behavior: 'smooth'
      });
  };

    const setLanguage = (lang) => {
        setLang(prev => lang);
        localStorage.setItem("lang", lang);
        window.location.reload(false);
    }

    const setActive = (e,menu_id) => {
      e.preventDefault();
      setMenus(prev => {
          let tmp = prev;
          tmp.forEach(item => {
              item.active = false;
                if(item.menu_id === menu_id){
                    item.active = true;
                    item.show = true;
                }
                let submenus = item.child;
                submenus.forEach(element => {
                    element.active = false;
                    if(element.menu_id === menu_id){
                        item.active = true;
                        element.active = true;
                        element.show = true;
                    }
                });
          });
          return tmp;
      });
      setChkMenu(menu_id);
    }

    return (
      <>
      <header style={{"background":header_color}}>
          <nav>
            <div className={language==="th"?'warning hide':'warning'}>
                <div className="info container">
                  <FaExclamationTriangle />
                  <p>{lang('','Sorry for the inconvenience, Some information is currently being translated.','带来不便敬请谅解 目前正在翻译一些信息。')}</p>
                </div>
            </div>
            <div className="container">
                <div className="logo">
                  <a href={process.env.PUBLIC_URL + "/"}><img src={logo} alt={lang(init.name_th,init.name_en,init.name_cn)}/></a>
                  <h3>{lang(init.name_th,init.name_en,init.name_cn)}</h3>
                </div>
                <div className="menu">
                  <div className="mobile-menu">
                    <button onClick={e => setMobileMenu(true)} className="mobile-nav-open">
                    <FaAlignRight/>
                    </button>
                    <div className={mobile_menu?'mobile-nav active':'mobile-nav'} style={{"background":header_color}}>
                      <ul className="top">
                        <li>
                          <a href={'#close'} onClick={e => setMobileMenu(false)}>X</a>
                        </li>
                        <li className={"mobile-menu-language group"}>
                            {language==="th"?(<div><img src={th_icon} alt="ภาษาไทย" /> ไทย <FaChevronDown /></div>):''}
                            {language==="en"?(<div><img src={en_icon} alt="ENGLISH" /> Eng <FaChevronDown /></div>):''}
                            {language==="cn"?(<div><img src={ch_icon} alt="简体中文" /> 中文 <FaChevronDown /></div>):''}
                            <div className="submenu invisible group-hover:visible">
                              <ul className="">
                                <li><button onClick={()=>setLanguage("th")}><img src={th_icon} alt="ภาษาไทย" /> ภาษาไทย</button></li>
                                <li><button onClick={()=>setLanguage("en")}><img src={en_icon} alt="ENGLISH" /> ENGLISH</button></li>
                                <li><button onClick={()=>setLanguage("cn")}><img src={ch_icon} alt="简体中文" /> 简体中文</button></li>
                              </ul>
                            </div>
                        </li>
                      </ul>
                      <ul className="ul-menu-nav">
                      {menus.map((menu, key) =>(
                          <li key={"mobile-menu"+key} className={menu.active?"active group":'group'}>
                          <a href={menu.link} onClick={e=>setActive(e,menu.menu_id)}>{lang(menu.menu_th,menu.menu_en,menu.menu_cn)}</a>
                          {
                            (menu.child.length>0)?(
                              <div className="submenu hidden group-hover:block">
                                <ul>
                                {menu.child.map((sub_menu, sub_menu_key) =>(
                                  <li key={"mobile-sub_menu"+sub_menu_key} className={sub_menu.active?"active":''}>
                                  <a href={sub_menu.link} onClick={e=>setActive(e,sub_menu.menu_id)}>{lang(sub_menu.menu_th,sub_menu.menu_en,sub_menu.menu_cn)}</a>
                                  </li>
                                ))}
                                </ul>
                              </div>
                            ):""
                          }
                          </li>
                      ))}
                      </ul>
                      <ul className="ul-menu-info">
                        <li className="info">
                          <a href={process.env.PUBLIC_URL + "/"} ><img src={logo} alt={lang(init.name_th,init.name_en,init.name_cn)}/></a>
                          <h3>{lang(init.name_th,init.name_en,init.name_cn)}</h3>
                        </li>
                        <li className="open">
                          <p>{lang('เวลาทำการ','OPENING HOUR','营业时间')}: </p>
                          <p>{init.open_info}</p>
                        </li>
                        <li className="social">
                          <ul>
                              <li><a href={init.facebook} target="_blank" rel="noreferrer" className="rounded-btn"><FaFacebookF /></a></li>
                              <li><a href={init.twitter} target="_blank" rel="noreferrer" className="rounded-btn"><FaTwitter /></a></li>
                              <li><a href={`mailto:`+init.email} className="rounded-btn"><FaEnvelope /></a></li>
                              <li><a href={`tel:`+init.phone} className="rounded-btn"><FaPhoneAlt /></a></li>
                          </ul>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="desktop-menu">
                    <ul>
                    { menus.map((menu, key) =>(
                        <li key={"menu"+key} className={menu.active?"group active":"group"}>
                        <a href={process.env.PUBLIC_URL + menu.link}>{lang(menu.menu_th,menu.menu_en,menu.menu_cn)}</a>
                        {
                          (menu.child.length>0)?(
                            <div className="submenu invisible group-hover:visible">
                              <ul>
                              {menu.child.map((sub_menu, sub_menu_key) =>(
                                <li className={sub_menu.active?"active":''} key={"sub_menu"+sub_menu_key} style={{"background":header_color}}>
                                <a href={process.env.PUBLIC_URL + sub_menu.link} >{lang(sub_menu.menu_th,sub_menu.menu_en,sub_menu.menu_cn)}</a>
                                </li>
                              ))}
                              </ul>
                            </div>
                          ):""
                        }
                        </li>
                    ))}
                    </ul>
                  </div>
                </div>
                <div className="top-nav">
                    <div className="left">
                    </div>
                    <div className="phone"><FaPhoneAlt /> {init.phone}</div>
                    <div className="language group">
                        {language==="th"?(<div><img src={th_icon} alt="ภาษาไทย" /> ไทย</div>):''}
                        {language==="en"?(<div><img src={en_icon} alt="ENGLISH" /> ENG</div>):''}
                        {language==="cn"?(<div><img src={ch_icon} alt="简体中文" /> 中文</div>):''}
                      <ul className="invisible group-hover:visible">
                          <li><button onClick={()=>setLanguage("th")}><img src={th_icon} alt="ภาษาไทย" /> ภาษาไทย</button></li>
                          <li><button onClick={()=>setLanguage("en")}><img src={en_icon} alt="ENGLISH" /> ENGLISH</button></li>
                          <li><button onClick={()=>setLanguage("cn")}><img src={ch_icon} alt="简体中文" /> 简体中文</button></li>
                      </ul>
                    </div>
                    <div className="social">
                      <ul>
                          <li><a href={init.facebook} target="_blank" rel="noreferrer"><FaFacebookF /></a></li>
                          <li><a href={init.twitter} target="_blank" rel="noreferrer"><FaTwitter /></a></li>
                          <li><a href={`mailto:`+init.email} target="_blank" rel="noreferrer"><FaEnvelope /></a></li>
                      </ul>
                    </div>
                    <div className="open">{init.open_info}</div>
                </div>
            </div>
          </nav>
      </header>
      <section style={{"background":bg_color}}>
          <Suspense fallback={<h1>Loading</h1>}>
            <Outlet />
          </Suspense>
          {/* <!--begin::Scrolltop Button--> */}
          <ScrollToTop />
          {/* <!--end::Scrolltop Button--> */}
      </section>
      <footer>
        <Footer init={init}/>
      </footer>
      </>
    );
}

export default Layout