import { useState, useEffect } from "react"
import { lang, img_url } from '../../lang'
import { useNavigate } from 'react-router-dom';
import api from '../api'
export default function Knowledge() {
    const [articles, setArticles] = useState([]);
    const [types, setTypes] = useState([]);
    const [all_types, setAllTypes] = useState('12:13');
    const [type, setType] = useState('12:13');
	const navigate = useNavigate();

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
              const response = await api.get('/type/parent/11');
              setTypes(response.data.results);
              let all = '';
              response.data.results.forEach(element => {
                    if(all!==''){all+=':';}
                    all += element.id;
              });
              setAllTypes(all);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    useEffect(()=>{
        const fetchData = async () =>{
            try {
              const response = await api.get('/article/category/'+type);
              setArticles(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();
    },[type]);

    return (
        <>
        <div className="container">
            <div className="title">
                <div className="conner"></div>
                <div className="title-h1"><h1>{lang("คลังความรู้","Knowledge","知识")}</h1></div>
            </div>
            <div className="menu">
                <ul>
                    <li onClick={e=>setType(all_types)}>{lang("หนังสือ","Book","书本")}</li>
                    <li onClick={e=>setType(all_types)}>{lang("สื่อเผยแพร่ความรู้","Other Media","传播知识的媒介")}</li>
                </ul>
            </div>
            <div className="km-container">
                    {articles.map((item, index) => (
                        <div className={"card"} key={item.id}>
                            <div className={"image"} onClick={() => navigate(item.url)} title={lang(item.title_th,item.title_en,item.title_cn)}>
                                <div className={"image-inner"} style={{ backgroundImage: `url(${img_url + item.cover_img})` }}></div>
                            </div>
                            {/* <div className="info">
                                <div className="title" onClick={() => navigate(item.url)}>{lang(item.title_th,item.title_en,item.title_cn)}</div>
                                <div className="detail" dangerouslySetInnerHTML={{__html: lang(item.desc_th,item.desc_en,item.desc_cn)}}></div>
                            </div> */}
                            <div className="card-end"></div>
                        </div>
                    ))}
            </div>
            <div className="all-events">
                <button onClick={() => navigate('/knowledge')}>{lang("ดูเพิ่มเติม","View more","查看更多")}</button></div>
        </div>
        </>
    )
}
