import { useEffect, useState } from 'react'
import { lang, validateEmail } from '../lang'
import api from './api'
import axios from 'axios'
import { FaMobileAlt } from 'react-icons/fa';
import { FaTriangleExclamation, FaFileLines, FaXmark } from 'react-icons/fa6';
import { loadCaptchaEnginge, LoadCanvasTemplate , validateCaptcha} from "react-simple-captcha";

export default function Footer({ init }) {
    const [error, setError] = useState(0);
    const [success, setSuccess] = useState(0);
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [contact_msg, setContactMsg] = useState('');
    const [msg, setMsg] = useState('');
    const [ip, setIP] = useState('');

    useEffect(()=>{
        setError(0);
        setSuccess(0);
        setMsg('');
        loadCaptchaEnginge(4);
        fetchInit();
    },[]);

    const fetchInit = async () =>{
        try {
            const ip_response = await axios.get('https://geolocation-db.com/json/');
            setIP(ip_response.data.IPv4);
        } catch (err) {
            if(err.response){
                //Not in 202 response range
                console.log(err.response.data);
                console.log(err.response.headers);
                console.log(err.response.status);
            } else {
                console.log(`Error: ${err.message}`);
            }
        }
    }

    const onSubmit = (event) => {
        event.preventDefault();
        setError(0);
        setSuccess(0);
        setMsg('');
        let user_captcha = document.getElementById('user_captcha_input').value;
        if (validateCaptcha(user_captcha)===true) {
            if(!name){
                let message = lang('กรุณากรอกชื่อติดต่อ..','Please enter name..',"请输入名字..");
                setError(1);
                setMsg(message);
                return
            }else if(!email||!validateEmail(email)){
                let message = lang('กรุณาตรวจสอบอีเมล์ผู้จอง..','Please enter valid Email..',"请输入有效的邮箱..");
                setError(1);
                setMsg(message);
                return
            }else if(!contact_msg){
                let message = lang('กรุณาตรวจสอบข้อความที่ต้องการส่ง..','Please enter message..',"请输入留言内容..");
                setError(1);
                setMsg(message);
                return
            }else{
                // create new contact
                const jsonData = {
                    name: name,
                    email: email,
                    phone: '',
                    page: 'footer',
                    message: contact_msg,
                    user: name,
                    ip: ip,
                } 
                api.post(`msg/create`,jsonData)
                .then(response => {
                    if(response.data.status===true){
                        setSuccess(1);
                        loadCaptchaEnginge(4);

                        setName('');
                        setEmail('');
                        setContactMsg('');
                        document.getElementById('user_captcha_input').value = "";
                    }else{
                        setError(1);
                        let message = JSON.stringify(response.data.message);
                        setMsg(message);
                    }
                })
                .catch(error => {
                    setError(1);
                    setMsg(error);
                });
            }
        }else{
            let message = 'The Captcha is invalid..';
            setError(1);
            setMsg(message);
        }
    }

  return (
    <>
    <div className="container">
        <div className="info">
            <div className="rounded-btn"><FaMobileAlt size={"1.25em"} /></div>
            <h3>Contact Us</h3>
            <p>Mobile: {init.phone}</p>
            <p>Email: {init.email}</p>
        </div>
        {(init.map)?(
        <div className="map"><iframe name="footer-map" src={init.map} title={lang(init.name_th,init.name_en,init.name_cn)}></iframe></div>
        ):''}
        <div className="contact">
            <h3>{lang('ติดต่อเรา','Contact us','联系我们')}</h3>
            <form>
                <p></p>
                {(error===1)?(
                    <div className="notice danger">
                        <div className={"notice-info"}><FaTriangleExclamation /> {msg}</div>
                        <FaXmark onClick={(event)=>setError(0)} />
                    </div>
                    ):''}
                {(success===1)?(
                <div className="notice active">
                    <div className={"notice-info"}><FaFileLines /> {lang('บันทึกข้อมูลเรียบร้อย!','Successfuly!','Thank You!')}</div>
                    <FaXmark onClick={(event)=>{setSuccess(0);loadCaptchaEnginge(4)}} />
                </div>
                ):''}
                <fieldset>
                    <input type="text" name="Name" id="name" placeholder="Name" value={(name)?name:''} onChange={e=>setName(e.target.value)}/>
                </fieldset>
                <fieldset>
                    <input type="text" name="Email" id="email" placeholder="Email" value={(email)?email:''} onChange={e=>setEmail(e.target.value)} />
                </fieldset>
                <fieldset>
                    <textarea name="Message" id="message" cols="30" rows="3" placeholder="Message" value={(contact_msg)?contact_msg:''} onChange={e=>setContactMsg(e.target.value)}></textarea>
                </fieldset>
                <fieldset className="captcha">
                    <label htmlFor="captcha"><LoadCanvasTemplate /></label>
                    <input placeholder="Enter Captcha Value" id="user_captcha_input" name="user_captcha_input" type="text" />
                </fieldset>
                <fieldset className="right">
                    <button type="submit" onClick={e=>onSubmit(e)}>{lang('ตกลง','Submit','提交')}</button>
                </fieldset>
            </form>
        </div>
    </div>
    <div className="container copyright">
        <p>{lang('© สงวนลิขสิทธิ์ เว็บไซต์อาคารเฉลิมพระเกียรติ ๗ รอบ พระชนมพรรษา','Copyright © 2022 Chiang Mai University Library. All rights reserved','Copyright © 2022 Chiang Mai University Library. All rights reserved')} <br />
        {lang('โดย สำนักหอสมุด มหาวิทยาลัยเชียงใหม่',' ',' ')}</p>
        <a href={"/panel"} target="_blank" rel="noopener noreferrer">(ระบบหลังบ้าน)</a>
    </div>
    </>
  )
}