import React from 'react';
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";

export default function SwiperNavigation(props) {
  return (
    <div className=''>
      <div className='art-slider-navigation flex justify-between'>
        {/* left side */}
        <div className='art-sn-left'>
          {/* slider pagination */}
          <div className={props.section + '-swiper-pagination'}></div>
        </div>

        {/* right side */}
        <div className='art-sn-right'>
          {/* slider navigation */}
          <div className='art-slider-nav-frame grid grid-cols-2 gap-4'>
            {/* prev */}
            <div
              className={'art-slider-nav highlight art-' + props.section + '-swiper-prev'}
              role='button'
              aria-label='Previous slide'
            >
              <FaChevronLeft />
            </div>
            {/* next */}
            <div
              className={'art-slider-nav highlight art-' + props.section + '-swiper-next'}
              role='button'
              aria-label='Next slide'
            >
              <FaChevronRight />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
