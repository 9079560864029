import { useState, useEffect } from "react"
import { lang, img_url } from '../../lang'
import { useNavigate } from 'react-router-dom';
import api from '../api'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { Swiper, SwiperSlide } from "swiper/react";
import Fancybox from "../Template/fancybox";

SwiperCore.use([Navigation, Pagination, A11y]);
export default function Gallery() {
    const [images, setImages] = useState([]);
    const [vdo, setVDO] = useState([]);
    const [type, setType] = useState(12);
	const navigate = useNavigate();

    useEffect(()=>{
        const fetchInit = async () =>{
        }
        fetchInit();
    },[]);

    useEffect(()=>{
        const fetchData = async () =>{
            try {
              const response = await api.get('/image_home/'+type);
              setImages(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
            try {
              const response = await api.get('/vdos/'+type+'?show=5');
              setVDO(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();
    },[type]);

    return (
        <>
        <div className="container">
            <div className="title">
                <div className="title-h1"><h2>{lang("คลังภาพ","Galleries","相册")}</h2></div>
                <div className="foot"></div>
            </div>
            <div className="menu">
                <ul>
                    <li onClick={e=>setType(12)}>{lang("อัลบัมภาพ","Album","相片集")}</li>
                    <li onClick={e=>setType(13)}>{lang("วีดีโอ","VDO","视频")}</li>
                </ul>
            </div>
        </div>
        <div className="gallery-container" style={{"background":`${(type===13)?'#fff':''}`}}>
            <Fancybox>
            {(type===12)?(
                images.map((item, index) => (                        
                    <div className={"card"} key={item.id}>
                        <div className="card-overlay"></div> 
                        <a href={img_url + item.url}
                            data-fancybox="gallery" 
                            data-caption={`<b>${lang(item.name_th,item.name_en,item.name_cn)}</b>`}
                            >
                        <img className={Math.floor(Math.random()*5)%2===1?'aspect-square':'aspect-square'} src={img_url + item.url} alt={lang(item.name_th,item.name_en,item.name_cn)}/>
                        
                        <div className="card-details fadeIn-bottom">
                         <h3 className="card-title">{lang(item.name_th,item.name_en,item.name_cn)}</h3>
                         <p className="card-text"><button>{lang("ดูรายละเอียด","View more","查看更多")}</button></p>
                     </div>
                     </a>
                    </div>
                ))
            ):''}
            </Fancybox>
            {(type===13)?(
            <div className="vdo-slide">
                <div className="vdo-container">
                {/* group items  */}
                <Swiper
                    slidesPerView={3}
                    spaceBetween={10}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      // when window width is >= 480px
                      480: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      // when window width is >= 640px
                      640: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      // when window width is >= 992
                      992: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      // when window width is >= 1200
                      1200: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      // when window width is >= 1500
                      1500: {
                        slidesPerView: 3,
                        spaceBetween: 20
                      }
                    }}
                    pagination={{
                        el: ".vdo-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-vdo-swiper-next",
                      prevEl: ".art-vdo-swiper-prev"
                    }}
                    modules={[Pagination]}
                    effect={"fade"}
                    className="vdo-swiper"
                >
                    {vdo.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <div className={"card"}>
                                <iframe name={'vdo_'+item.id} title={lang(item.name_th,item.name_en,item.name_cn)} src={item.link}/>
                                <div className="info">
                                    <div className="title" onClick={() => navigate(item.link)}>{lang(item.name_th,item.name_en,item.name_cn)}</div>
                                </div>
                                <div className="card-end"></div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                </div>
                <div className="vdo-nav">
                    <SwiperNavigation section="vdo" />
                </div>
            </div>
            ):''}
        </div>
        </>
    )
}
