import React, { useState, useEffect } from "react";
import { FaChevronUp } from "react-icons/fa";

export default function ScrollToTop() {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
      window.addEventListener("scroll", () => {
          if (window.scrollY > 200) {
              setShowTopBtn(true);
          } else {
              setShowTopBtn(false);
          }
        //   console.log(showTopBtn || "hidden");
      });
  }, []);

  const goToTop = () => {
      window.scrollTo({
          top: 0,
          behavior: "smooth",
      });
  };

  return (
    <>
      <button id="to-top-button" onClick={goToTop} title="Go To Top" className={"gototop-btn "+(showTopBtn ?"flex": "hidden")}>
        <FaChevronUp />
    </button>
    </>
  );
}
