import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { lang, img_url, month_th_mini, month_en_mini, month_cn } from '../../lang'

export default function NewsCard(props) {
  const [day, setDay] = useState(1);
  const [month, setMonth] = useState(lang(month_th_mini[0],month_en_mini[0],month_cn[0]));
  const [year, setYear] = useState(2022);
	const navigate = useNavigate();

  useEffect(()=>{
    let event_date = props.item.create_time;
      if(event_date){
        setDay(event_date.substr(8,2));
        let event_month = Number(event_date.substr(5,2))-1;
        let month_name = month_en_mini[event_month];
        setMonth(month_name);
        setYear(event_date.substr(0,4));
    }
    if(props.item.detail){
      event_date = JSON.parse(props.item.detail);
      if(event_date.start_date){
        setDay(event_date.start_date.substr(8,2));
        let event_month = Number(event_date.start_date.substr(5,2))-1;
        let month_name = month_en_mini[event_month];
        setMonth(month_name);
        setYear(event_date.start_date.substr(0,4));
      }
    }
  },[]);

  if(props.item.id===undefined){
    return null;
  }
  return (
    <div className={"card " + (props.class || "")}>
      <div className={"image"} onClick={() => navigate(props.item.url)}>
        <div className={"image-inner"} style={{ backgroundImage: `url(${img_url + props.item.cover_img})` }}></div>
      </div>
      <div className="info">
        <div className="date">
          <div className="day">{day}</div>
          <div className="month">{month}</div>
          <div className="year">{year}</div>
        </div>
        <div className="title" onClick={() => navigate(props.item.url)}>{lang(props.item.title_th,props.item.title_en,props.item.title_cn)}</div>
        <div className="detail" dangerouslySetInnerHTML={{__html: lang(props.item.info_th,props.item.info_en,props.item.info_cn)}}></div>
        <div className="viewmore" onClick={() => navigate(props.item.url)}>{lang('รายละเอียด','More Details','细节')}</div>
      </div>
      <div className="card-end"></div>
    </div>
  );
}
