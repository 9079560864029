// export const img_url = 'http://localhost/hmk84/images/';
export const img_url = 'https://hmk84.cmu.ac.th/images/';
export const month_th = [ "มกราคม", "กุมภาพันธ์", "มีนาคม", "เมษายน", "พฤษภาคม", "มิถุนายน", "กรกฎาคม", "สิงหาคม", "กันยายน", "ตุลาคม", "พฤศจิกายน", "ธันวาคม"];
export const month_th_mini = [ "ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."];
export const month_en = ["January","February","March","April","May","June","July","August","September","October","November","December"];
export const month_en_mini = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul","Aug", "Sep", "Oct", "Nov", "Dec"];
export const month_cn = ["一月", "二月", "三月", "四月", "五月", "六月", "七月","八月", "九月", "十月", "十一月", "十二月"];
export function addLeadingZeros(num, totalLength) {
    return String(num).padStart(totalLength, '0');
}
export const login_url = "https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=u0zRs9emUCw6msXrzSJpaCRJ1kb6kScTfKaXhdYD&redirect_uri=https://hmk84.lib.cmu.ac.th/bookrecommend/api/callback&scope=cmuitaccount.basicinfo mishr.self.basicinfo&state=admin";
    
export function chk_img(img){
    if(img && img !== undefined){
        return img;
    }else{
        return img_url + 'slide.png';
    }
}
export function lang(name_th, name_en, name_ch){
    let language = localStorage.getItem("lang");
    if(language===null){
        language = "th";
        localStorage.setItem("lang","th");
    } 
    if((name_en===''||name_en===null)&&language==='en'){
        name_en = name_th;
        localStorage.setItem("warning",true);
    }
    if((name_ch===''||name_ch===null)&&language==='cn'){
        name_ch = name_th;
        localStorage.setItem("warning",true);
    }

    switch(language) {
        case 'en':
            return name_en;
        case 'cn':
            return name_ch; 
        default:
            return name_th;
    }
}

export function getDate(event_date){
    let day_name = event_date.substr(8,2);
    let year_name = Number(event_date.substr(0,4));
    let event_month = Number(event_date.substr(5,2))-1;
    let month_name = lang(month_th[event_month], month_en[event_month], month_en[event_month]);

    let language = localStorage.getItem("lang");
    if(language==='en'){
        return day_name+' '+month_name+' '+year_name;
    }else if(language==='cn'){
        year_name = year_name+543;
        return day_name+' '+month_name+' '+year_name;
    }else{
        year_name = year_name+543;
        return day_name+' '+month_name+' '+year_name;
    }
}
function wait(delay: number) {
  return new Promise( resolve => setTimeout(resolve, delay) );
}
export const validateEmail = (mail) => {
  return String(mail)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
};