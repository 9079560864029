import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NewsCard from "./NewsCard";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang, img_url } from '../../lang'
import api from '../api'

SwiperCore.use([Navigation, Pagination]);
export default function Slide({article}) {
    const [images, setImages] = useState([]);
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
                let article_id = 1;
                if(article.id){
                    article_id = article.id;
                }
              const response = await api.get('/gallery/'+article_id);
              setImages(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[article]);

    return (
        <div className="slide-container">
            {/* group items  */}
            <Swiper
                spaceBetween={5}
                slidesPerView={1}
                speed={900}
                autoplay={{ delay: 3000 }}
                pagination={{
                  type: "progressbar",
                }}
                modules={[Pagination]}
                className="slide-swiper"
            >
                {images.map((item, index) => (
                    <SwiperSlide className="swiper-slide" key={index}>
                        <div className={"card "}>
                            <img src={img_url + item.url} alt={lang(item.name_th,item.name_en,item.name_cn)}/>
                            <div className="info">
                            <div className="title">{lang(item.name_th,item.name_en,item.name_cn)}</div>
                            </div>
                        </div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    )
}
