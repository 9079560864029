import { useState, useEffect } from "react"
import { lang, img_url } from '../../lang'
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { useNavigate } from 'react-router-dom';
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import api from '../api'

SwiperCore.use([Navigation, Pagination, A11y]);
export default function Exhibition() {
    SwiperCore.use([Autoplay]); // start autoplay
    const [articles, setArticles] = useState([]);
    const [types, setTypes] = useState([]);
    const [all_types, setAllTypes] = useState('5:6');
    const [type, setType] = useState('5:6');
	const navigate = useNavigate();

    useEffect(()=>{
        const fetchInit = async () =>{
            try {
              const response = await api.get('/type/parent/4');
              setTypes(response.data.results);
              let all = '';
              response.data.results.forEach(element => {
                    if(all!==''){all+=':';}
                    all += element.id;
              });
              setAllTypes(all);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchInit();
    },[]);

    useEffect(()=>{
        const fetchData = async () =>{
            try {
              const response = await api.get('/article/category/'+type);
              setArticles(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
        }
        fetchData();
    },[type]);

    return (
        <>
        <div className="container">
            <div className="title">
                <div className="conner"></div>
                <div className="title-h1"><h1>{lang("นิทรรศการ","Exhibitions","展览")}</h1></div>
            </div>
            <div className="menu">
                <ul>
                    <li onClick={e=>setType(all_types)}>{lang("นิทรรศการทั้งหมด","ALL","全部")}</li>
                    {
                        
                        types.map((item, index) => (
                            <li onClick={e=>setType(item.id)} key={item.id}>{lang(item.type_th,item.type_en,item.type_cn)}</li>
                        ))
                    }
                </ul>
            </div>
            <div className="exhibition-container">
                {/* group items  */}
                <Swiper
                    slidesPerView={5}
                    centeredSlides={true}
                    spaceBetween={10}
                    speed={900}
                    autoplay={{ delay: 3000 }}
                    grabCursor={true}
                    breakpoints={{
                      // when window width is >= 320px
                      320: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      // when window width is >= 480px
                      480: {
                        slidesPerView: 1,
                        spaceBetween: 10
                      },
                      // when window width is >= 640px
                      640: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      // when window width is >= 992
                      992: {
                        slidesPerView: 2,
                        spaceBetween: 10
                      },
                      // when window width is >= 1200
                      1200: {
                        slidesPerView: 4,
                        spaceBetween: 10
                      },
                      // when window width is >= 1500
                      1500: {
                        slidesPerView: 5,
                        spaceBetween: 5
                      }
                    }}
                    pagination={{
                        el: ".exhibition-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-exhibition-swiper-next",
                      prevEl: ".art-exhibition-swiper-prev"
                    }}
                    modules={[Pagination]}
                    effect={"fade"}
                    className="exhibition-swiper"
                >
                    {articles.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <div className={"card"}>
                                <div className={"image"} onClick={() => navigate(item.url)}>
                                    <div className={"image-inner"} style={{ backgroundImage: `url(${img_url + item.cover_img})` }}></div>
                                </div>
                                <div className="info">
                                    <div className="title" onClick={() => navigate(item.url)}>{lang(item.title_th,item.title_en,item.title_cn)}</div>
                                    <div className="detail" dangerouslySetInnerHTML={{__html: lang(item.info_th,item.info_en,item.info_cn)}}></div>
                                </div>
                                <div className="card-end"></div>
                            </div>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SwiperNavigation section="exhibition" />
            </div>
            <div className="all-events">
                <button onClick={() => navigate('/exhibition')}>{lang("ดูเพิ่มเติม","View more","查看更多")}</button></div>
        </div>
        </>
    )
}
