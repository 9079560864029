import React from 'react'
import { useNavigate } from 'react-router-dom';
import SwiperCore, { Navigation, Pagination, A11y, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import NewsCard from "./NewsCard";
import SwiperNavigation from "../Template/Swiper/SwiperNavigation";
import { lang } from '../../lang'

SwiperCore.use([Navigation, Pagination, A11y]);
export default function News(props) {
	const navigate = useNavigate();
    SwiperCore.use([Autoplay]); // start autoplay

    return (
        <div className="container">
            <h1>{lang("ข่าวและกิจกรรม","News and Activities","消息和活动")}</h1>
            <div className="news-container">
                {/* group items  */}
                <Swiper
                    spaceBetween={5}
                    slidesPerView={1}
                    speed={900}
                    autoplay={false}
                    modules={[Pagination]}
                    pagination={{
                        el: ".group-swiper-pagination",
                        type: "bullets",
                        clickable: true
                    }}
                    navigation={{
                      nextEl: ".art-group-swiper-next",
                      prevEl: ".art-group-swiper-prev"
                    }}
                    effect={"fade"}
                    className="group-swiper"
                >
                    {props.data.map((item, index) => (
                        <SwiperSlide className="swiper-slide" key={index}>
                            <NewsCard key={index} item={item}/>
                        </SwiperSlide>
                    ))}
                </Swiper>
                <SwiperNavigation section="group" />
        
            </div>
            <div className="all-events">
                <button onClick={() => navigate('/activities')}>{lang("ดูเพิ่มเติม","View more","查看更多")}</button></div>
        </div>
    )
}
