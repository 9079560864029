import React, { lazy } from "react"
import { Routes, Route } from "react-router-dom"
import Layout from "./components/Layout"
import Home from "./views/Home"



// const Home = lazy(() => wait(1000).then(() => import("./views/Home")))
// const Home = lazy(() => import("./views/Home"))
const News = lazy(() => import("./views/News"))
const Activities = lazy(() => import("./views/Activities"))
const Exhibitions = lazy(() => import("./views/Exhibitions"))
const Galleries = lazy(() => import("./views/Galleries"))
const Vdos = lazy(() => import("./views/Vdos"))
const Store = lazy(() => import("./views/Store"))
const Knowledge = lazy(() => import("./views/Knowledge"))
const Panorama = lazy(() => import("./views/Panorama"))
const Page = lazy(() => import("./views/Page"))
const Article = lazy(() => import("./views/Article"))
// const About = lazy(() => import("./views/About").then(module => {
//   return { default: module.About }
// }))

const AccessRegis = lazy(() => import("./usertrack/views/Home"))
const AccessRegisCallback = lazy(() => import("./usertrack/views/Callback"))

function App() {
  
  return (
    <Routes>
        <Route path="/" element={<Layout />} >
            <Route path="*" element={<Home />} />
            <Route path="/" element={<Home />} />
            <Route path="/gallery" element={<Galleries />} />
            <Route path="/vdo" element={<Vdos />} />
            <Route path="/about" element={<Page article_id="14"/>} />
            <Route path="/contact" element={<Page article_id="20"/>} />

            <Route path="/news" element={<News />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/activities/:articleId" element={<Store />} />

            <Route path="/article/:articleId" element={<Article />} />
            <Route path="/article/:articleId/:articleTitle" element={<Article />} />

            <Route path="/additional-info" element={<Page article_id="19"/>} />

            <Route path="/exhibition" element={<Exhibitions />} />
            <Route path="/exhibition/:typeId" element={<Exhibitions />} />
            <Route path="/knowledge" element={<Knowledge />} />
            <Route path="/knowledge/:typeId" element={<Knowledge />} />

            <Route path="/album" element={<Store />} />
            <Route path="/vdo" element={<Store />} />
            <Route path="/panorama" element={<Panorama />} />
        </Route>
        <Route path="/welcome" element={<AccessRegis />} />
        <Route path="/callback" element={<AccessRegisCallback />} />
    </Routes>
  );
}

export default App;
