import { useState, useEffect, lazy, Suspense, useTransition } from "react"
import { useNavigate } from 'react-router-dom';
import { lazyLoad } from "../lazyLoad.js"
import { lang, img_url } from '../lang'
import slide from '../assets/slide.png';
import api from '../components/api'
import News from '../components/Homepage/News'
import Exhibition from '../components/Homepage/Exhibition'
import Knowledge from '../components/Homepage/Knowledge'
import Gallery from '../components/Homepage/Gallery'
import Slide from '../components/Homepage/Slide'

export default function Home() {
    const [init, setInit] = useState([]);
    const [article, setArticle] = useState([]);
    const [news, setNews] = useState([]);
	const navigate = useNavigate();
    // const [isAdmin, setIsAdmin] = useState(false);
    // const [isPending, startTransition] = useTransition();

    useEffect(()=>{
        localStorage.setItem("warning",false);
        const fetchInit = async () =>{
            try {
              const response = await api.get('/article/1');
              setArticle(response.data.results);
            } catch (err) {
                if(err.response){
                    //Not in 202 response range
                    console.log(err.response.data);
                    console.log(err.response.headers);
                    console.log(err.response.status);
                } else {
                    console.log(`Error: ${err.message}`);
                }
            }
          try {
            const response = await api.get('/setting');
            setInit(response.data.results);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
          try {
            const response = await api.get('/article/category/2:3?order=id%20desc');
            setNews(response.data.results);
          } catch (err) {
              if(err.response){
                  //Not in 202 response range
                  console.log(err.response.data);
                  console.log(err.response.headers);
                  console.log(err.response.status);
              } else {
                  console.log(`Error: ${err.message}`);
              }
          }
        }
        fetchInit();
    },[]);
  
    return (
        <>
            {/* <div className="slide"><iframe name="homepage-panorama" src={`http://localhost/hmk84/2014/slide/floorout`} title={lang(init.name_th,init.name_en,init.name_cn)}></iframe></div> */}
            <div className="homepage">
                <div className="main-slide">
                    <Slide article={article} />
                </div>
                <section className="container about-us">
                    <div className="info">
                        <h1>{lang(article.title_th,article.title_en,article.title_cn)}</h1>
                        <p dangerouslySetInnerHTML={{__html: lang(article.desc_th,article.desc_en,article.desc_cn)}}></p>
                        <div className="all-events">
                            <div className="view-more" onClick={() => navigate('/about')}>{lang('รายละเอียด','More Details','细节')}</div>
                        </div>
                    </div>
                    <div className="img">
                        {(article.id)?(
                            <img src={img_url+article.cover_img} alt={lang(article.title_th,article.title_en,article.title_cn)} />
                        ):''}
                    </div> 
                </section>
                <section className="news-activity">
                    <News data={news} />
                </section>
                <section className="exhibition">
                    <Exhibition />
                </section>
                <section className="slide">
                    <div className="img">
                        <img src={slide} alt={lang(article.title_th,article.title_en,article.title_cn)} /></div>
                </section>
                <section className="knowledge">
                    <Knowledge />
                </section>
                <section className="gallery">
                    <Gallery />
                </section>
            </div>
        </>
    )
}